.headerNav {
  background: #222;
}

.menu {
  list-style-type: none;
  display: flex;
}

.menuItem {
  padding: 10px 15px;
  cursor: pointer;
  color: #939597;
  margin: auto 0;
}

.languageSelectorItem {
  padding: 10px 15px;
  color: #939597;
}

.fieldWrapper {
  width: 100px;
  height: 100%;
  margin: auto 0;
  text-align: left;
}

.fieldWrapper select {
  color: #939597;
}

.fieldWrapper select:hover{
  color: #f6f9fc;
}

.activeMenuItem {
  padding: 10px 15px;
  cursor: pointer;
  color: #f6f9fc;
  border-bottom: solid 3px;
  border-bottom-color: #ff8353;
  margin: auto 0 0 0;
}
