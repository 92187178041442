.inputWrapper {
  display: flex;
  flex-direction: column;
}

.inputField {
  padding: 5px 5px;
  border: 1px solid #222;
  border-radius: 2px;
}

.errorInputField {
  padding: 5px 5px;
  border: 2px solid #c71717;
  border-radius: 2px;
}

.errorText {
  color: #c71717;
}
