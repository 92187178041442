.footer {
  background: #222;
  padding: 5px 35px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}

.footerText {
  color: #f6f9fc;
}
