.videoWrapper169 {
  aspect-ratio: 16 / 9;
}

.videoWrapper43 {
  aspect-ratio: 4 / 3;
}

.titleText {
  color: #222;
}

.DocumentWrapper {
  border: solid 1px #222;
  padding: 15px;
}
