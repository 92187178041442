.tableWrapper {
  /* padding: 1rem; */
}

.table {
  border-spacing: 0;
  color: #222;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  text-align: left;
}

.headerGroupRow {
}

.headerGroupCell {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 5px 10px;
  background: rgba(0, 0, 0, 0.04);
  min-width: 100px;
  max-width: 200px;
}

.dataRow {
}

.dataRowPointer {
  cursor: pointer;
}

.dataRowPointer:hover {
  background: rgba(0, 0, 0, 0.01);
}

.dataCell {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 5px 10px;
  min-width: 100px;
  max-width: 200px;
}

.pagination {
  padding: 0.5rem;
}
